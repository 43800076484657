import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../app-config-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = false;
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  authNavStatus$ = this._authNavStatusSource.asObservable();
  private apiUrl = "";

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) {
    this.loggedIn = !!localStorage.getItem('auth_token');
    this._authNavStatusSource.next(this.loggedIn);
    this.apiUrl = this.appConfigService.getConfig().apiUrl;
   }

   isLoggedIn() {
    return this.loggedIn;
  }

  login(mail: string, password: string): Observable<string> {
    return this.httpClient.post(`${this.apiUrl}auth/login`, { "userName": mail, "password": password })
      .pipe(map((res: any): any => {
        localStorage.setItem('auth_token', res.auth_token);
        this.loggedIn = true;
        this._authNavStatusSource.next(true);
        return true;
      }));

  }

  logout() {
    localStorage.removeItem('auth_token');
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
  }
  

}
