import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { HttpTransportType } from '@microsoft/signalr';
import { CallModel } from '../interfaces/callmodel.model';
import { AppConfigService } from '../app-config-service.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private apiUrl = "";
  signalReceived = new EventEmitter<CallModel>();

constructor(private appConfigService: AppConfigService) {
  this.apiUrl = this.appConfigService.getConfig().apiUrl;
 }


private hubConnection: signalR.HubConnection
public startConnection = () => {

  const token = localStorage.getItem('auth_token');
  let tokenValue = '';
  if (token !== '') {
    tokenValue = '?token=' + token;
  }

  this.hubConnection = new signalR.HubConnectionBuilder()
                          .withUrl(`${this.apiUrl}callhub${tokenValue}` )
                          .configureLogging(signalR.LogLevel.Trace)
                          .withAutomaticReconnect()
                          .build();
  this.hubConnection
    .start()
    .then(() => console.log('Connection started'))
    .catch(err => console.log('Error while starting connection: ' + err))
  
}
public addTransferCallDataListener = () => {
  this.hubConnection.on('transfercalldata', (data: CallModel) => {
    console.log('signal received:');
    this.signalReceived.emit(data);
    console.log('signal received done:');
  });
}
}