<div id="header">
    <div id="logoArea" (click)="togglePhoneBook()">
        <img src="/assets/images/rigitel-cti.png" title="RigiTel" id="rigiTelIcon">
    </div>
    <div id="staffStateArea">
        <span *ngFor="let staff of staffList" class="staffItem">
            <span [style.color]="staff.color" class="staffName">{{staff.acronym}}</span> 
                <img src="/assets/images/call-active.svg" title="Beschäftigt"  *ngIf="staff.state == 'busy'">
                <img src="/assets/images/call-finished.svg" title="Frei"  *ngIf="staff.state == 'free'">
         </span>
        
    </div>
</div>
<table class="tableFixHead" id="phoneTable">
    <thead>
    <tr>
        <th>Datum/Zeit</th>
        <th>Richtung</th>
        <th>Status</th>
        <th>Von</th>
        <th>Nach</th>
        <th>Telefonnummer</th>
        <th class="duration">Min</th>
    </tr>
    </thead>
    <ng-container *ngFor="let data of callList; trackBy: trackMessage">
        <tr class="first">
            <td [ngClass]="data.billable ? 'highlightRed' : (data.notable ? 'highlightOrange' :  (data.important ? 'highlightGreen' :  (data.comment ? 'highlightYellow' : '')))"><nobr>{{data.dateTime}}</nobr></td>
            <td>
                <img src="/assets/images/call-incoming.svg" title="Ankommend"  *ngIf="data.direction == CallDirectionType.Inbound">
                <img src="/assets/images/call-outgoing.svg" title="Abgehend"  *ngIf="data.direction == CallDirectionType.Outbound">
            </td>
            <td>
                <img src="/assets/images/call-ringing.svg" title="Klingelt" *ngIf="data.status == CallStatusType.Ringing">
                <img src="/assets/images/call-active.svg" title="Aktiv" *ngIf="data.status == CallStatusType.Active">
                <img src="/assets/images/call-finished.svg" title="Fertig" *ngIf="data.status == CallStatusType.Finished">
                <img src="/assets/images/call-notanswered.svg" title="Unbeantwortet" *ngIf="data.status == CallStatusType.FinishedNotAnswered">
            </td>
            <td [style.background-color]="data.fromColor"><a href="tel:{{data.from}}" >{{data.from}} {{data.fromAcronym}}</a></td>
            <td [style.background-color]="data.toColor"><a href="tel:{{data.to}}">{{data.to}} {{data.toAcronym}}</a></td>
            <td>{{data.displayName}}</td>
            <td class="duration">{{data.duration}}</td>
        </tr>
        <tr class="second" >
            <td [ngClass]="data.billable ? 'highlightRed' : (data.notable ? 'highlightOrange' :  (data.important ? 'highlightGreen' :  (data.comment ? 'highlightYellow' : '')))">
                <div *ngIf="data.status != 0">
                    G: <input type="checkbox" [(ngModel)]="data.important" (change)="saveData(data)"/>&nbsp;
                    O: <input type="checkbox" [(ngModel)]="data.notable" (change)="saveData(data)"/>&nbsp;
                    R: <input type="checkbox" [(ngModel)]="data.billable" (change)="saveData(data)"/>&nbsp;
                </div>
            </td>
            <td colspan="6">
                <div *ngIf="data.status != 0">
                    <input type="textbox" class="comment" [(ngModel)]="data.comment" (change)="saveData(data)"/>
                </div>
            </td>
        </tr>
        <tr class="third">
            <td [ngClass]="data.billable ? 'highlightRed' : (data.notable ? 'highlightOrange' :  (data.important ? 'highlightGreen' :  (data.comment ? 'highlightYellow' : '')))"></td>
            <td colspan="7"></td>
        </tr>
     </ng-container>
 
</table>

<div id="phoneBook" *ngIf="showPhoneBook"  (click)="togglePhoneBook()">
    <h2>Telefonbuch</h2>
    <table>
        <tr>
            <th>Nummer</th>
            <th>Kürzel</th>
            <th>Name</th>
        </tr>
        <ng-container *ngFor="let phone of internalPhoneList">
            <tr>
                <td>{{phone.number}}</td>
                <td>{{phone.acronym}}</td>
                <td>{{phone.displayName}}</td>
            </tr>
        </ng-container>
    </table>
</div>

