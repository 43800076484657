import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceHelper } from './serviceHelper';
import { CallModel } from '../interfaces/callmodel.model';
import { StaffModel } from '../interfaces/staffmodel.model';
import { CallUpdateModel } from '../interfaces/callupdatemodel.model';
import { AppConfigService } from '../app-config-service.service';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  private apiUrl = "";


  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService)
  {
    this.apiUrl = this.appConfigService.getConfig().apiUrl;
  }

  getCallList(): Observable<CallModel[]>
  {
    return this.httpClient.get<CallModel[]>(`${this.apiUrl}Calls`, {headers: ServiceHelper.getHeader()}).pipe(map((results: any): CallModel[] => results));
  }
  updateCall(id: number, call: CallUpdateModel): Observable<CallModel>
  {
    return this.httpClient.post<CallUpdateModel>(`${this.apiUrl}Calls/${id}`, call,{headers: ServiceHelper.getHeader()}).pipe(map((results: any): CallModel => results));
  }
  getStaffPhones(): Observable<StaffModel[]>
  {
    return this.httpClient.get<StaffModel[]>(`${this.apiUrl}Calls/staffs`, {headers: ServiceHelper.getHeader()}).pipe(map((results: any): StaffModel[] => results));
  }
}
