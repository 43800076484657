import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CallModel } from '../interfaces/callmodel.model';
import { CallUpdateModel } from '../interfaces/callUpdatemodel.model';
import { SignalRService } from '../services/signal-r.service';
import { CallService } from '../services/call.service';
import { CallDirectionType } from '../interfaces/callDirectionType.model';
import { CallStatusType } from '../interfaces/callStatusType.model';
import { StaffModel } from '../interfaces/staffmodel.model';

@Component({
  selector: 'app-call-dashboard',
  templateUrl: './call-dashboard.component.html',
  styleUrls: ['./call-dashboard.component.scss']
})
export class CallDashboardComponent implements OnInit {
  signalList: CallModel[] = [];
  callList: CallModel[] = [];
  staffList: StaffModel[] = [];
  internalPhoneList: StaffModel[] = [];
  latestCallId: string;
  CallStatusType = CallStatusType;
  CallDirectionType = CallDirectionType;
  showPhoneBook: boolean = false;
  
  
  constructor(
    public signalRService: SignalRService, 
    public callService: CallService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    console.log('start getCallList');
    // Display Initital List
    this.callService.getCallList().subscribe(calls => { 
      console.log('retrieved getCallList');
      //console.log(calls);
      this.callList = calls;
      this.updateStaffSate();
    } );

    this.callService.getStaffPhones().subscribe(staffs => { 
      console.log('retrieved getStaffPhones');

      staffs.filter(x => x.color).forEach(staff =>
        {
          var stuffFound = this.staffList.find(x => x.acronym == staff.acronym);
          if(!stuffFound)
          {
            var staffModel = new StaffModel();
            staffModel.acronym = staff.acronym;
            staffModel.color = staff.color;
            this.staffList.push(staffModel);
          }
          

        });

      this.internalPhoneList = staffs;
      console.log(staffs);
      console.log(this.internalPhoneList);
      this.updateStaffSate();
    } );

    // Start Real Time Events
    console.log('start real time events');
    this.signalRService.startConnection();
    this.signalRService.addTransferCallDataListener();   
   // this.startHttpRequest();
    this.signalRService.signalReceived.subscribe((signal: CallModel) => 
    { 

      this.latestCallId = signal.callID;
      console.log('processing signal ' + signal);

     
     this.signalList.push(signal);
      var object = this.callList.find(x => x.callID == signal.callID);
      if(object)
      {
        console.log('existing');  
        let index = this.callList.indexOf(object);
        this.callList[index] = signal;
      }
      else
      {
        
        console.log('new');  
        this.callList.unshift(signal);
        if(signal.direction == CallDirectionType.Inbound
          && signal.status != CallStatusType.Ringing)
        {
          var duplicateItem = this.callList.find(
            x => x.from == signal.from
                 && x.direction == CallDirectionType.Inbound
                 && x.status == CallStatusType.Ringing);
          if(duplicateItem)
          {
            console.log('remove duplicate');
            this.callList = this.callList.filter(x => x !== duplicateItem);
          }
       

        }
      }
      this.updateStaffSate();
      console.log('done');  
    });
  }

  saveData(data: CallModel)
  {
    console.log('saveData');
    var update = new CallUpdateModel();
    update.billable = data.billable;
    update.notable = data.notable;
    update.comment = data.comment;
    update.important = data.important;
    this.callService.updateCall(data.id, update).subscribe(calls => { 
      console.log('executed update');
    } );
  }

  trackMessage(index: number, callModel: CallModel) {
    return callModel.callID + callModel.status;
  }
  updateStaffSate() {
  
    var activeCalls  = this.callList.filter(x => x.status == CallStatusType.Active);
    this.staffList.forEach(staff =>
      {
        var activeCall = activeCalls.find(x => x.fromAcronym == staff.acronym || x.toAcronym == staff.acronym);
        if(activeCall)
          staff.state = "busy";
        else
          staff.state = "free";

      });
  }
  togglePhoneBook()
  {
    this.showPhoneBook = !this.showPhoneBook;
  }

}

