import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//AppConfig.d.ts
export interface AppConfig {
  apiUrl: string;
}

//AppConfigService.ts
@Injectable({
  providedIn: 'root'
})


export class AppConfigService {
  private config: AppConfig;
  loaded = false;
  constructor(private http: HttpClient) {}
  loadConfig(): Promise<void> {
      return this.http
          .get<AppConfig>('/app.config.json')
          .toPromise()
          .then(data => {
              this.config = data;
              this.loaded = true;
          });
  }
  
  getConfig(): AppConfig {
      return this.config;
  }
}
