import { Component, OnInit } from '@angular/core';
import { SignalRService } from './services/signal-r.service';
import { HttpClient } from '@angular/common/http';
import { CallModel } from './interfaces/callmodel.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'WVTPhoneWeb';
  signalList: CallModel[] = [];
  callList: CallModel[] = [];

  constructor() { }
  
  ngOnInit() {

  }
 
}
