import { HttpHeaders } from "@angular/common/http";

export class ServiceHelper {
  public static getHeader(): HttpHeaders {
    let authToken = localStorage.getItem('auth_token');
    //return new HttpHeaders();
    return new HttpHeaders({ 
        'Authorization': `Bearer ${authToken}`,
        'Access-Control-Allow-Origin': `https://cti.rigitel.ch` 
      });
    return;
  }
}