import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginForm = new FormGroup({
    mail: new FormControl('', Validators.required),
    password: new FormControl('')
  });

  loginMessage: string;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    
    this.loginMessage = 'Trying to log in ...';


    var ctrls = this.loginForm.controls;
    this.authService.login(ctrls.mail.value, ctrls.password.value).subscribe(user => {
      this.router.navigate(['/']);
    },
      error => {
        this.loginMessage = "Der Benutzername oder das Passwort sind leider falsch. Bitte versuchen Sie es noch einmal.";

      }

    )

  }

}
