<h1>Login WVT.Phone</h1>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">

        <div class="alert alert-danger" *ngIf="loginMessage">
          {{loginMessage}}
        </div>
        <div class="form-group">
          <label for="type">Email Address</label>
          <input type="text" class="form-control" id="mail" formControlName="mail">
        </div>
        <div class="form-group">
          <label for="type">Password</label>
          <input type="password" class="form-control" id="password" formControlName="password">
        </div>
        <button type="submit" [disabled]="!loginForm.valid" class="btn btn-primary">Login</button>
      </div>
    </div>
  </div>
</form>